import 'bootstrap-datepicker';
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.pl';

import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

$('.winners-calendar-container').each(function() {
    let that = this;
    $('.winners-calendar', that).datepicker({
        format: 'yyyy-mm-dd',
        startDate: $(this).data('min'),
        endDate: $(this).data('max'),
        defaultViewDate: 'today',
        startView: 'days',
        minViewMode: 'days',
        maxViewMode: 'days',
        keyboardNavigation: false,
        weekStart: 1,
        language: 'pl'
    })
    .on('changeDate', function () {
        let selectedDate = $(this).datepicker('getFormattedDate');
        $('.winners-calendar-date', that).text(selectedDate.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3.$2.$1'));
        $('.winners-calendar-list > *', that).addClass('d-none');
        let $winners = $(`.winners-calendar-list > [data-date=${selectedDate}]`, that);
        $winners.removeClass('d-none');
        $('.winners-calendar-list-empty', that).toggleClass('d-none', !!$winners.length);
    })
    .datepicker('update', $(this).data('date'))
    .trigger('changeDate');
});

let bWinnersSlider;

$('.b-winners__slider .swiper').each(function(){

    let awards = $(this).parent().siblings('.b-winners__awards'),
        awardsItems = awards.children('.b-winners__awards-item');

    bWinnersSlider = new Swiper($(this)[0], {
        slidesPerView: 1,
        loop: true,
        navigation: {
            nextEl: $(this).siblings('.swiper-button-next')[0],
            prevEl: $(this).siblings('.swiper-button-prev')[0]
        },
        modules: [Navigation, Pagination],
        on: {
            slideChange: function(Swiper) {

                let activeIndex = Swiper.realIndex;

                awardsItems.eq(activeIndex).fadeIn(200).siblings().hide();

            }
        }
    });

});

let bAwardsSlider;

$('.b-awards__week-slider .swiper').each(function(){

    bAwardsSlider = new Swiper($(this)[0], {
        slidesPerView: 1,
        loop: true,
        navigation: {
            nextEl: $(this).siblings('.swiper-button-next')[0],
            prevEl: $(this).siblings('.swiper-button-prev')[0]
        },
        modules: [Navigation, Pagination]
    });

});