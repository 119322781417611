import './validator/initialisation';

export function getRecaptcha() {
	let url = new URL(document.querySelector('script[src^="https://www.google.com/recaptcha/api.js"]').src);
	let params = new URLSearchParams(url.search);
	return grecaptcha.execute(params.get('render'));
};

$.fn.ajaxInvalidFeedback = function(options) {
	return this.each(function() {
		let object = $(this).data('ajaxInvalidFeedback');
		if (typeof options == 'object') {
			$(this).addClass('has-invalid-feedback-ajax').after(`
				<div class='invalid-feedback invalid-feedback-ajax d-block form-field__error"'>${options.error}</div>
			`);
			let destroyHandler = () => $(this).ajaxInvalidFeedback('destroy');
			$(this).on('change input', destroyHandler);
			$(this.form).on('submit', destroyHandler);
			$(this).data('ajaxInvalidFeedback', {destroyHandler});
		} else if (options === 'destroy') {
			$(this).off('change input', object.destroyHandler).removeClass('has-invalid-feedback-ajax').siblings('.invalid-feedback-ajax').remove();
			$(this.form).off('submit', object.destroyHandler);
			$(this).data('ajaxInvalidFeedback', null);
		}
	});
};

$.fn.toggleValid = function (valid) {
    this.each((i, element) => element.setCustomValidity(valid ? "" : 'invalid'));
};

export function processFormErrors(errors, form) {
	for (let fieldName in errors) {
		if (fieldName == form.name) {
			for (let error of errors[fieldName]) {
				alert(error);
			}
		} else {
			let error = errors[fieldName].join('<br>');
			$(form.elements[fieldName]).ajaxInvalidFeedback({error: error});
		}
	}
};

export async function setRecaptchaResponse(form) {
    if ($(form).data('recaptcha') && !$(form.elements).filter('.g-recaptcha-response').length) {
		let fieldName = typeof $(form).data('recaptcha') == 'string' ? $(form).data('recaptcha') : 'g-recaptcha-response';
        $(form).append(`<input type="hidden" name="${fieldName}" class="g-recaptcha-response">`);
    }
    if ($(form.elements).filter('.g-recaptcha-response').length) {
        $(form.elements).filter('.g-recaptcha-response').val(await getRecaptcha());
    }
};

$('.ajax-form').on('submit', async function(event) {
	event.preventDefault();
	$(this.elements).filter('[type=submit]').prop('disabled', true);
	await setRecaptchaResponse(this);

	try {
		var data = await $.ajax({
			type: 'post',
			url: this.action,
			data: new FormData(this),
			processData: false,
			contentType: false,
		});
	} catch (xhr) {
		let errorEvent = $.Event('submit-error');
		$(this).trigger(errorEvent, xhr);
		if (!errorEvent.isDefaultPrevented()) {
			if (xhr.responseJSON && xhr.responseJSON.errors) {
				processFormErrors(xhr.responseJSON.errors, this);
			} else {
				alert("Wystąpił nieznany błąd, spróbuj ponownie później.");
			}
		}
		$('html, body').animate({
			scrollTop: $(this).offset().top - 96
		}, 0);
		return;
	} finally {
		$(this.elements).filter('[type=submit]').prop('disabled', false);
	}
	let successEvent = $.Event('submit-success');
	$(this).trigger(successEvent, data);
	if (!successEvent.isDefaultPrevented()) {
		this.reset();
		$(this).removeClass('was-validated');
		$(this).find('.has-invalid-feedback-ajax').ajaxInvalidFeedback('destroy');
		$(this).find('.is-valid').removeClass('is-valid');
		$(this).find('.is-invalid').removeClass('is-invalid');
		$(this).find('.form-file .btn-remove').hide();
		$(this).find('img[data-src]').each(function() {
			$(this).attr('src', $(this).data('src'));
		});
	};
});

$('.checkbox').each(function(){

	let that = $(this),
		btn = $(this).find('.checkbox__btn');

    if (btn.attr('id') !== 'rule-button') {
        btn.click(function(e) {

            e.preventDefault();
            e.stopPropagation();

            if ($(this).closest('.checkbox').hasClass('checkbox--is-rolled')) {
                $(this).removeClass('d-contents').removeClass('ml-4').text('Więcej');
                $(this).closest('.checkbox').removeClass('checkbox--is-rolled');
            } else {
                $(this).addClass('d-contents').addClass('ml-4').text('Mniej');
                $(this).closest('.checkbox').addClass('checkbox--is-rolled');
            }
        });
    }

	if(that.outerHeight() < that.find('.checkbox__content').outerHeight()) {
		that.addClass('checkbox--rollow');
	} else {
		that.removeClass('checkbox--rollow');
	}

    $('#start-reminder-form .checkbox').addClass('checkbox--rollow');

	$(window).resize(function(){
		if(that.outerHeight() < that.find('.checkbox__content').outerHeight()) {
			that.addClass('checkbox--rollow');
		} else {
			that.removeClass('checkbox--rollow');
		}

        $('#start-reminder-form .checkbox').addClass('checkbox--rollow');
	});

});