import 'vanilla-cookieconsent/dist/cookieconsent';
import 'vanilla-cookieconsent/dist/cookieconsent.css';

var cc = initCookieConsent();

cc.run({
    current_lang: 'pl',
    autoclear_cookies: true,                   // default: false
    // page_scripts: false,                        // default: false
    // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
    // delay: 0,                               // default: 0
    // auto_language: null                     // default: null; could also be 'browser' or 'document'
    // autorun: true,                          // default: true
    // force_consent: false,                   // default: false
    // hide_from_bots: false,                  // default: false
    // remove_cookie_tables: false             // default: false
    // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
    // cookie_expiration: 182,                 // default: 182 (days)
    // cookie_necessary_only_expiration: 182   // default: disabled
    // cookie_domain: location.hostname,       // default: current domain
    // cookie_path: '/',                       // default: root
    // cookie_same_site: 'Lax',                // default: 'Lax'
    // use_rfc_cookie: false,                  // default: false
    // revision: 0,                            // default: 0

    languages: {
        'pl': {
            consent_modal: {
                title: 'Pliki Cookies',
                description: 'Drogi Użytkowniku, na niniejszej witrynie wykorzystywane są pliki cookies i podobne technologie. Korzystanie z witryny bez zmiany ustawień dotyczących cookies oznacza, że będą one umieszczone w Twoim urządzeniu.\n' +
                    'Administratorem danych osobowych zbieranych w ramach korzystania z niniejszej witryny jest IQ Marketing (Poland) sp. z o.o. z siedzibą w Warszawie (powyższe nie dotyczy danych wprowadzanych w formularz zgłoszeniowy do Loterii – kwestie te reguluje Regulamin Loterii). Zbierane dane to m. in.: liczba odwiedzin, kraj, lokalizacja, przeglądarka, czas wizyty. Dane te zbierane są w celu zapewnienia prawidłowego funkcjonowania strony, w celu utrzymania sesji na stronie oraz zapewnienia bezpieczeństwa, a także w celach analitycznych i statystycznych. Odbiorcami danych są także partnerzy administratora świadczący usługi związane z funkcjonowaniem portalu.\n' +
                    'Przysługuje Ci prawo do żądania od administratora dostępu, sprostowania, usunięcia lub ograniczenia przetwarzania danych, wniesienia sprzeciwu, a także prawo skorzystania z innych praw wymienionych szczegółowo w Polityce Prywatności [link do polityki]. Tam też znajdują się informacje jak zakomunikować nam wolę skorzystania z tych praw.\n' +
                    'Oświadczam, iż zapoznałem/am się z obowiązkiem informacyjnym administratora danych osobowych zawartym w <a href="/files/polityka_prywatnosci.pdf" target="_blank">polityce prywatności</a>.',
                primary_btn: {
                    text: 'Akceptuję',
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                }
            },
            settings_modal: {
                title: 'Preferencje plików cookie',
                save_settings_btn: 'Zapisz',
                accept_all_btn: 'Akceptuję',
                close_btn_label: 'Zamknij',
                cookie_table_headers: [
                    {col1: 'Imię'},
                    {col2: 'Domena'},
                    {col3: 'Wygaśnięcie'},
                    {col4: 'Opis'}
                ],
                blocks: [
                    {
                        title: 'Wykorzystanie plików cookie',
                        description: 'Drogi Użytkowniku, na niniejszej stronie wykorzystywane są pliki cookies i podobne technologie. Korzystanie z witryny bez zmian ustawień dotyczących cookies oznacza, że będą one umieszczone w Twoim urządzeniu. Administratorem danych osobowych zbieranych w ramach korzystania z niniejszej witryny jest IQ Marketing (Poland) sp. z o.o. z siedzibą w Warszawie. Zbierane dane to m. in. liczba odwiedzin, kraj, lokalizacja, przeglądarka, czas wizyty. Dane te zbierane są w celu zapewnienia prawidłowego funkcjonowania strony, w celu utrzymania sesji na stronie oraz zapewnienia bezpieczeństwa, a także w celach analitycznych i statystycznych. Odbiorcami danych są partnerzy administratora świadczący usługi związane z funkcjonowaniem strony.\n' +
                            '\n' +
                            'Przysługuje Ci prawo do żądania dostępu do swoich danych, ich sprostowania, usunięcia lub ograniczenia przetwarzania oraz prawo do wniesienia sprzeciwu wobec przetwarzania dokonywanego na podstawie prawnie uzasadnionego interesu administratora lub strony trzeciej, prawo do przenoszenia danych wobec przetwarzania dokonywanego w sposób zautomatyzowany i na podstawie umowy, a także prawo do wniesienia skargi do organu nadzorczej – Prezesa Urzędu Ochrony Danych Osobowych. Szczegółowe informacje dotyczące przetwarzania danych znajdują się w Polityce ochrony prywatności / link/ (linkowanie do polityka prywatnosci)\n' +
                            '\n' +
                            'Oświadczam, że zapoznałem/am się z informacjami dotyczącymi przetwarzania moich danych osobowych, które zawarte są w <a href="/files/polityka_prywatnosci.pdf" target="_blank">Polityce Prywatności</a>'
                    }
                ]
            }
        }
    },
	gui_options: {
        consent_modal: {
            layout: 'cloud',               // box/cloud/bar
            position: 'bottom center',     // bottom/middle/top + left/right/center
            transition: 'slide',           // zoom/slide
            swap_buttons: false            // enable to invert buttons
        },
        settings_modal: {
            layout: 'box',                 // box/bar
            // position: 'left',           // left/right
            transition: 'slide'            // zoom/slide
        }
    }
});